<div class="z-10 w-full h-full backdrop-blur-xl flex justify-center items-center" *ngIf="showCouponForm">
    <div class="mx-6 rounded-3xl shadow-2xl bg-white justify-center w-[400px] max-h-[450px] md:w-[500px] lg:w-[600px] lg:max-h-[600px] p-6 "> 
        <form [formGroup]="form" (submit)="addCoupon()">
            <div class="grid grid-rows-4 place-items-center gap-4">
                <p class="text-neutral-400">{{couponFormTitle}}</p>
                <input class="text-center" formControlName="coupon"  [placeholder]="couponFormPlaceholder">
                <button type="submit" class="text-red-600">{{couponFormAddButton}}</button>
                <button (click)="closeCouponForm()">{{couponFormBackButton}}</button>
            </div>
        </form>
    </div>
</div>

<div class="mb-12 grid p-6">
    <div class="w-full grid">
        <app-cross (click)="close()"  class="justify-self-end"></app-cross>
    </div>
    <div *ngIf="isLogged">
        <div *ngIf="currentState == states.LOADING">
            <div  class="flex justify-center items-center">
                <ng-lottie [options]="loader" class="lg:h-[200px]"></ng-lottie>
            </div>
         </div>
        <div *ngIf="currentState == states.DEFAULT">
            <div class="flex justify-center">
                <div class="mt-6 mx-2 grid grid-flow-row gap-4 ">
                    <app-cart-item [cartReservation]="reservation" *ngFor="let reservation of cart.reservations"></app-cart-item>
                    <app-cart-item [cartProduct]="product" (addEmitter)="addProductQuantity(product)" (rmvEmitter)="removeProductQuantity(product)" *ngFor="let product of cart.products"></app-cart-item>
                    <div class="flex justify-end underline" (click)="openCouponForm()">
                        <p class="text-neutral-400">Ho un codice sconto</p>
                        <ng-lottie [options]="coupon" [width]="'32px'" ></ng-lottie>
                    </div>
                    <div class="flex overflow-scroll gap-x-2">
                        <div *ngFor="let c of coupons" class="flex p-2 gap-x-2 rounded-md bg-gray-300">
                            <p class="text-white">{{c}}</p>
                            <app-cross [width]="'24px'" [height]="'24px'" class="bg-white rounded-full" (click)="removeCoupon(c)"></app-cross>
                        </div>
                    </div>
                    <div class="flex justify-between">
                        <p class="font-light">Totale</p>
                        <p class="font-light">{{cart.totalAmount}}€</p>
                    </div>
                    <div class="flex justify-between">
                        <p class="font-semibold">Totale scontato</p>
                        <p class="font-semibold">{{cart.toPayAmount}}€</p>
                    </div>
                    <button *ngIf="cart.reservations.length != 0 || cart.products.length != 0" class="bg-default_grey px-5 py-3 text-white" (click)="payment()">Acquista ora</button>
                </div>
            </div>
        </div>
        <div *ngIf="currentState == states.PAYMENT">
            <app-pay-card (closePayment)="currentState = states.DEFAULT" (snackEmitter)="showSnackBar($event)"></app-pay-card>
        </div>
        <div *ngIf="currentState == states.EMPTY">
            <div class="h-screen flex justify-center p-12">
                <div class="flex flex-col gap-y-12">
                    <h2 class="text-xl md:text-2xl lg:text-3xl font-bold text-center">Il carrello è vuoto</h2>
                    <ng-lottie [options]="empty" class="lg:h-[300px]"></ng-lottie>
                </div>
                
            </div>
         </div>
       
    </div>
    <div *ngIf="!isLogged">
        <h2 class="text-l md:text-xl lg:text-2xl font-bold my-8 text-center">Devi effettuare l'accesso!</h2>
        <div  class="flex justify-center items-center">
            <ng-lottie [options]="empty" class="lg:h-[200px]"></ng-lottie>
        </div>
    </div>
</div>
